import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button } from "../../buttons";
import { SimpleInput } from "../../inputs";
import { shouldForwardProp } from "../../utils";

export const Container = styled("div")`
  ${({ theme }): SerializedStyles => css`
    position: relative;
    height: auto;
    margin: 0 auto;
    padding: ${theme.spacing(8, 3)};
    background-color: ${theme.palette.background.paper};
    border-radius: ${theme.shape.siteBorderRadius.sm};
    box-shadow: ${theme.shadows[1]};
    position: relative;
    overflow: hidden;
    text-align: center;
    ${theme.breakpoints.up("sm")} {
      border-radius: ${theme.shape.siteBorderRadius.lg};
      padding: ${theme.spacing(10, 22)};
      height: auto;
      max-width: 900px;
    }
  `}
`;

export const BackgroundImage = styled(GatsbyImage)`
  ${({ theme }): SerializedStyles => css`
    position: absolute !important;
    z-index: 10;
    ${theme.cssMixins.stretchAll};
    &:after {
      content: "";
      position: absolute;
      ${theme.cssMixins.stretchAll};
      ${theme.cssMixins.transitionalCTABgFilter};
    }
  `}
`;

export const GridLayout = styled(Grid)`
  position: relative;
  z-index: 10;
  height: 100%;
`;

export const Badge = styled(Typography)`
  ${({ theme }): SerializedStyles => css`
    position: absolute;
    top: 0;
    border-radius: 0 0 ${theme.shape.siteBorderRadius.sm}
      ${theme.shape.siteBorderRadius.sm};
    max-height: 30px;
    padding: ${theme.spacing(0.625, 2.25)};
    background-color: ${theme.palette.secondary.main};
    ${theme.breakpoints.up("sm")} {
      right: 0;
      border-radius: 0 0 0 ${theme.shape.siteBorderRadius.sm};
    }
  `}
`;

type PpBackgroundImage = {
  $backgroundImage: string | null;
};

export const Title = styled(Typography, {
  shouldForwardProp,
})<PpBackgroundImage>`
  ${({ $backgroundImage, theme }): SerializedStyles => css`
    ${$backgroundImage && `background-image: url(${$backgroundImage})`};
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: ${theme.spacing(3, 0)};
  `}
`;

export const FormContainer = styled(Grid)`
  width: 100%;
`;

export const Form = styled("form")`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(2)};
    ${theme.cssMixins.rowStretch};
    ${theme.breakpoints.down("sm")} {
      ${theme.cssMixins.colCentered};
    }
  `}
`;

export const StyledInput = styled(SimpleInput)`
  ${({ theme }): SerializedStyles => css`
    color: ${theme.palette.primary.main};
    width: 100%;
    height: 50px;
    ${theme.breakpoints.up("sm")} {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  `}
`;

export const StyledFormButton = styled(Button)`
  ${({ theme }): SerializedStyles => css`
    padding-left: ${theme.spacing(6)};
    padding-right: ${theme.spacing(6)};
    height: 50px;
    margin-top: ${theme.spacing(2)};
    ${theme.breakpoints.up("sm")} {
      margin-top: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: ${theme.shape.siteBorderRadius.lg};
      border-bottom-right-radius: ${theme.shape.siteBorderRadius.lg};
    }
  `}
`;

export const StyledButton = styled(Button)`
  ${({ theme }): SerializedStyles => css`
    margin-top: ${theme.spacing(2)};
  `}
`;
