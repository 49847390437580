import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { FilledInput } from "@mui/material";

export const StyledFilledInput = styled(FilledInput)`
  ${({ theme }): SerializedStyles => css`
    overflow: hidden;
    .MuiFilledInput-input {
      height: 100%;
      padding: ${theme.spacing(0, 2)};
    }
  `}
`;
