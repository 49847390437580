import React, { useRef } from "react";
import loadable from "@loadable/component";
import { Grid } from "@mui/material";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { SectionContainer } from "../../containers";
import { useHasBeenAlmostVisible } from "../../hooks";
import { RichText } from "../../textFields";
import { getPathname } from "../../utils";
import { BackgroundImage, Badge, Container, GridLayout, Title } from "./styles";

const TransitionalCTACollectEmail = loadable(
  () => import("./TransitionalCTACollectEmail"),
  {
    resolveComponent: (components) => components.TransitionalCTACollectEmail,
  }
);
const TransitionalCTALinkPage = loadable(
  () => import("./TransitionalCTALinkPage"),
  {
    resolveComponent: (components) => components.TransitionalCTALinkPage,
  }
);
const TransitionalCTAPopup = loadable(() => import("./TransitionalCTAPopup"), {
  resolveComponent: (components) => components.TransitionalCTAPopup,
});

export type PpTransitionalCTA = {
  action: {
    __typename: string;
    description?: string;
    page?: {
      url: string;
    };
    title?: string;
  };
  backgroundImage?: ImageDataLike | null;
  badgeText?: string;
  buttonText: string;
  description: string;
  onSubmit?: (e: React.FormEvent<HTMLInputElement>, arg: string) => void;
  popupContent?: React.ReactElement;
  shadowImage: string;
  title: string;
};

export function TransitionalCTA({
  action,
  backgroundImage,
  badgeText,
  buttonText,
  description,
  onSubmit,
  popupContent,
  shadowImage,
  title,
  ...rest
}: PpTransitionalCTA): React.ReactElement | null {
  const elementRef = useRef<HTMLDivElement>(null);
  const shouldRenderContent = useHasBeenAlmostVisible(elementRef);

  if (!action) return null;
  function renderCTA(): React.ReactElement | null {
    switch (action.__typename) {
      case "CMS_CollectEmailBlock":
        return (
          <TransitionalCTACollectEmail
            buttonText={buttonText}
            inputPlaceholder="Your email address"
            onSubmit={onSubmit}
          />
        );
      case "CMS_ShowPopupBlock":
        return (
          <TransitionalCTAPopup
            buttonText={buttonText}
            description={action?.description}
            popupContent={popupContent}
            title={action?.title}
          />
        );
      case "CMS_LinkPageBlock":
        return (
          <TransitionalCTALinkPage
            buttonText={buttonText}
            to={getPathname(action?.page.url)}
          />
        );
      default:
        return null;
    }
  }

  return (
    <SectionContainer {...rest}>
      <Container ref={elementRef}>
        {backgroundImage ? (
          <BackgroundImage alt="" image={getImage(backgroundImage)} />
        ) : null}
        <GridLayout
          container
          alignItems="center"
          direction="column"
          justifyContent="center"
        >
          {badgeText ? (
            <Badge color="primary.contrastText" variant="badge">
              {badgeText}
            </Badge>
          ) : null}
          <Grid item>
            <Title
              $backgroundImage={shouldRenderContent ? shadowImage : null}
              color={backgroundImage ? "primary.light" : "primary"}
              variant="h2"
            >
              {title}
            </Title>
          </Grid>
          <Grid item>
            <RichText
              gutterBottom
              color={backgroundImage ? "primary.contrastText" : "primary"}
              text={description}
              variant="body1"
            />
          </Grid>
          {renderCTA()}
        </GridLayout>
      </Container>
    </SectionContainer>
  );
}
