import React from "react";
import { StyledFilledInput } from "./styles";

type PpInput = {
  onChange: (args) => void;
  placeholder: string;
  value: string;
};

export function SimpleInput({
  onChange,
  placeholder,
  value,
  ...rest
}: PpInput): React.ReactElement {
  return (
    <StyledFilledInput
      disableUnderline
      aria-describedby="input-field"
      id="input-field"
      inputProps={{
        "aria-label": "input-field",
      }}
      labelWidth={0}
      // placeholder is hardcoded for v1, replace later
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      {...rest}
    />
  );
}
